<template>
    <div
        class="scroll-x"
    >
        <b-table
            class="mt-4 w-100"
            :busy="loading"
            striped
            bordered
            hover
            :items="semesters"
            :fields="fields"
            selectable
            select-mode="single"
            empty-text="There are no results that match your criteria."
            empty-filtered-text="There are no results that match your criteria."
        >
            <template #cell(email)="{ item }">
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
            </template>
            <template #cell(actions)="{ item }">
                <!--<a class="close" @click="row.toggleDetails">
                    <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </a>-->
                <div class="text-center">
                    <router-link
                        :to="{ name: 'admin_form', params: { formKey: 'edit-semester' }, query: { id: item.id } }"
                    >
                        <font-awesome-icon
                            icon="pencil-alt"
                        ></font-awesome-icon>
                    </router-link>
                </div>
            </template>
            <template #cell(is_active)="row">
                <b-checkbox
                    :key="row.item.id"
                    :checked="row.item.is_active"
                    @change="setActive(row.item)"
                ></b-checkbox>
            </template>
            <template #row-details="{ toggleDetails, item }">    
                <membership-form-info
                    @close="toggleDetails"
                    :user="item"
                ></membership-form-info>
                <login-info
                    :id="item.id"
                    :max="3"
                ></login-info>
            </template>
            <template #empty-text>
                There are no records to fetch.
            </template>
        </b-table>
    </div>
</template>
<script>
export default {
    props: ["loading", "semesters"],
    data() {
        return {
            fields: [
                {
                    key         : "id",
                    label       : "ID"
                },
                {
                    key         : "label",
                    label       : "Label",
                    sortable    : true
                },
                {
                    key         : "enrolled",
                    sortable    : true
                },
                {
                    key         : "start_time",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment(value).format("LLLL");
                    }
                },
                {
                    key         : "stop_time",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment(value).format("LLLL");
                    }
                },
                {
                    key         : "first_class_date",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment(value).format("L");
                    }
                },
                {
                    key : "actions"
                }
            ]
        }
    }
}
</script>