var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-x"},[_c('b-table',{staticClass:"mt-4 w-100",attrs:{"busy":_vm.loading,"striped":"","bordered":"","hover":"","items":_vm.semesters,"fields":_vm.fields,"selectable":"","select-mode":"single","empty-text":"There are no results that match your criteria.","empty-filtered-text":"There are no results that match your criteria."},scopedSlots:_vm._u([{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.email}},[_vm._v(_vm._s(item.email))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'admin_form', params: { formKey: 'edit-semester' }, query: { id: item.id } }}},[_c('font-awesome-icon',{attrs:{"icon":"pencil-alt"}})],1)],1)]}},{key:"cell(is_active)",fn:function(row){return [_c('b-checkbox',{key:row.item.id,attrs:{"checked":row.item.is_active},on:{"change":function($event){return _vm.setActive(row.item)}}})]}},{key:"row-details",fn:function(ref){
var toggleDetails = ref.toggleDetails;
var item = ref.item;
return [_c('membership-form-info',{attrs:{"user":item},on:{"close":toggleDetails}}),_c('login-info',{attrs:{"id":item.id,"max":3}})]}},{key:"empty-text",fn:function(){return [_vm._v(" There are no records to fetch. ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }