<template>
    <b-card-group
        class="mt-4"
        columns
    >
        <SemesterCard
            v-for="(semester, idx) in semesters"
            :key="idx"
            :semester="semester"
        />
    </b-card-group>
</template>
<script>
import SemesterCard from './SemesterCard.vue';

export default {
    props: ["loading", "semesters"],
    components: { SemesterCard }
}
</script>