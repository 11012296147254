<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="calendar-week"
                        class="mr-2"
                    ></font-awesome-icon>
                    Semesters
                </h2>
                <div class="mt-3">
                    <router-link
                        class="sc-btn pill"
                        :to="{ name: 'admin_form', params: { formKey: 'add-semester' } }"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="plus-circle"
                        ></font-awesome-icon>
                        Add Semester
                    </router-link>
                </div>
            </b-card>
        </b-skeleton-wrapper>
        <SemesterSwitchAlert />
        <b-form-radio-group
            v-model="viewMode"
            :options="options"
            buttons
            button-variant="outline-secondary"
        />
        <div
            :is="viewMode"
            :loading="loading"
            :semesters="semesters"
        ></div>
    </b-container>
</template>
<script>
import SemesterTable from './table/SemesterTable.vue';
import SemesterSwitchAlert from "./alert/SemesterSwitchAlert.vue";
import SemesterCardGroup from './cards/SemesterCardGroup.vue';

export default {
    components: { SemesterTable, SemesterSwitchAlert, SemesterCardGroup },
    data() {
        return {
            loading: false,
            semesters: [],
            viewMode: "SemesterCardGroup",
            options: [{ text: "Card View", value: "SemesterCardGroup" }, { text: "Table View", value: "SemesterTable" }]
        };
    },
    mounted() {
        this.loadSemesters();
    },
    methods: {
        loadSemesters() {
            this.$api.get(
                "/api/admin/semesters/list"
            ).then((response) => {
                this.semesters = response.data.semesters;
            });
        }
    }
}
</script>