<template>
    <b-card>
        <h4>{{ semester.label }}</h4>
        <div class="mt-2">
            <b-button
                variant="link"
                @click="switchSemester(semester)"
            >
                <font-awesome-icon
                    icon="exchange-alt"
                    class="mr-1"
                />
                Switch
            </b-button>
            <router-link
                :to="{ name: 'admin_form', params: { formKey: 'edit-semester' }, query: { id: semester.id } }"
                class="btn btn-link"
            >
                <font-awesome-icon
                    icon="pencil-alt"
                    class="mr-1"
                />
                Edit
            </router-link>
        </div>
    </b-card>
</template>
<script>
export default {
    props: ["semester"],
    methods: {
        async switchSemester(semester) {
            const { data: { status } } = await this.$api.post(
                "/api/admin/semesters/set-semester",
                { semester_id: semester.id }
            );

            if (status == "success") {
                this.$store.commit("setSemester", semester);
            }

            this.$bvToast.toast(status == "success" ? "Successfully switched semesters." : "Failed to switch semesters.", {
                title: status == "success" ? "Success!" : "Error!",
                variant: status == "success" ? "success" : "danger",
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    }
}
</script>