<template>
    <b-alert
        class="mt-2"
        variant="info"
        show
    >
        <font-awesome-icon icon="info-circle" class="mr-2" />
        Switching to a semester will not change the current (active) semester for members.
        To change the current semester for members, visit
        <router-link :to="{ name: 'group_settings', params: { group_id: group.group_id } }">group settings.</router-link>
    </b-alert>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState(["group"])
}
</script>